import {
    onSnapshot,
    doc,
    updateDoc,
    arrayUnion,
    getDoc,
    query,
    collection,
    orderBy,
    addDoc,
    where,
    getDocs,
    serverTimestamp,
} from 'firebase/firestore'
import React, { useRef, useEffect, useState } from 'react'
import { db } from '../../utils/firebase'
import { useChatStore } from '../../utils/useChatStore'
import { useUserStore } from '../../utils/userStore'
import { Box, Button, CircularProgress } from '@mui/material'
import {
    DeliveryOrderModal,
    PurchaseOrder,
    ViewModalPopup,
    DeliveryOrder,
    GRNModal,
    Invoice,
} from '../../components'
import { pdf } from '@react-pdf/renderer'
import axios from 'axios'
import { BACKEND_URL } from '../../constant'
import {
    cancelOrder,
    completeOrder,
    uploadPDF,
} from '../../endpoints/endpoints'

function ChatWindow() {
    const messagesEndRef = useRef<any>(null)
    const [chat, setChat] = useState<any>()
    const { chatId, img } = useChatStore()
    const {
        currentUser,
        productDetails,
        generateDO,
        handleDecline,
        decline,
        accept,
        clearDecline,
        clearAccept,
        clearPreview,
        preview,
        purchaseOrder,
        deliveryOrder,
        handleAccept,
        purchaseOrderId,
        purchaseDetail,
        requestDO,
        clearRequestDO,
        setAcceptDO,
        clearAcceptDO,
        // acceptDO,
        requestGRN,
        GRNRequest,
        clearRequestGRN,
        DeclineDO,
        setDeclineDO,
    } = useUserStore()
    const [displayChat, setDisplayChat] = useState(false)
    const [openModal, setOpenModal] = useState(false)
    const [selectedImageUrl, setSelectedImageUrl] = useState('')
    const [POFromOrder, setPOFromOrder] = useState()
    const [isGRNAccepted, setGRNAccepted] = useState(false)
    const [isInvoiceNeeded, setInvoiceNeeded] = useState(false)

    const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' })
    }

    const handlePreview = (pdfUrl: any) => {
        window.open(`${BACKEND_URL}/PDF/purchase-order-${pdfUrl}.pdf`, '_blank')
    }

    const handleDOPreview = (pdfUrl: any) => {
        window.open(`${BACKEND_URL}/PDF/${pdfUrl}.pdf`, '_blank')
    }

    const handleInvoicePreview = (pdfUrl: any) => {
        window.open(`${BACKEND_URL}/PDF/invoice-${pdfUrl}.pdf`, '_blank')
    }

    const handleGRNPreview = (pdfUrl: any) => {
        window.open(
            `${BACKEND_URL}/PDF/goods-received-note-${pdfUrl}.pdf`,
            '_blank'
        )
    }

    useEffect(() => {
        scrollToBottom()
    }, [chat?.messages, img.url, chatId, chat])

    useEffect(() => {
        scrollToBottom()
    }, [])

    //First Purchase Order

    const declinePO = async (purchaseId: any, purchaseDetail: any) => {
        try {
            // Step 1: Reference the messages sub-collection
            const messagesCollectionRef = collection(
                db,
                `chats/${chatId}/messages`
            )

            // Step 2: Query the messages sub-collection to find the message with the matching purchaseId
            const messageQuery = query(
                messagesCollectionRef,
                where('purchaseId', '==', purchaseId)
            )
            const messageSnapshot = await getDocs(messageQuery)

            if (!messageSnapshot.empty) {
                // Step 3: Update the `acceptOrDeclined` field in the matched messages
                messageSnapshot.forEach(async (docSnapshot) => {
                    await updateDoc(docSnapshot.ref, {
                        acceptOrDeclined: true, // Update the field to indicate that the order was declined
                    })
                })

                // Step 4: Add a new message to the messages sub-collection indicating that the order has been declined
                await addDoc(messagesCollectionRef, {
                    senderId: currentUser.id,
                    text: `The Purchase Order ${purchaseId} has been declined!`,
                    createdAt: serverTimestamp(), // Use Firestore's server timestamp
                })

                // Step 5: Call the function to cancel the order
                await cancelOrder(purchaseDetail.orderData.OrderID)

                // Step 6: Clear the decline state
            }
        } catch (error) {
            console.error('Error declining the purchase order:', error)
        }
    }

    const acceptPO = async (purchaseId: any, purchaseDetail: any) => {
        const chatDocRef = doc(db, 'chats', chatId)
        const chatDocSnapshot = await getDoc(chatDocRef)

        if (chatDocSnapshot.exists()) {
            // 1. Access the `messages` sub-collection
            const messagesCollectionRef = collection(
                db,
                `chats/${chatId}/messages`
            )

            // 2. Query to find the specific message with purchaseId
            const messageQuery = query(
                messagesCollectionRef,
                where('purchaseId', '==', purchaseId)
            )
            const messageSnapshot = await getDocs(messageQuery)

            // Assuming only one message should match the `purchaseId`, we'll update that message
            messageSnapshot.forEach(async (docSnapshot) => {
                const messageData = docSnapshot.data()

                // 3. Update the message's `acceptOrDeclined` field
                if (!messageData.acceptOrDeclined) {
                    await updateDoc(docSnapshot.ref, {
                        acceptOrDeclined: true,
                    })
                }
            })

            // 4. Add a new message as a sub-message
            await addDoc(messagesCollectionRef, {
                senderId: currentUser.id,
                purchaseAcceptOrder: true,
                text: `The Purchase Order ${purchaseId} has been accepted!`,
                createdAt: new Date(),
                purchaseDetail: purchaseDetail,
                generateDO: false,
            })
        }
    }
    //working

    const acceptDO = async (data: any) => {
        // Reference the messages sub-collection within the current chat
        const messagesCollectionRef = collection(db, `chats/${chatId}/messages`)

        try {
            // Query the messages sub-collection to find the message with the specific deliveryId
            const messageQuery = query(
                messagesCollectionRef,
                where('deliveryId', '==', data.deliveryId)
            )
            const messageSnapshot = await getDocs(messageQuery)

            if (!messageSnapshot.empty) {
                // If a message with the deliveryId exists, update its `acceptOrDecline` field
                messageSnapshot.forEach(async (docSnapshot) => {
                    const messageData = docSnapshot.data()

                    // Log the message for debugging
                    console.log('Found message to update: ', messageData)

                    // Update the specific message with the `acceptOrDecline` field set to true
                    await updateDoc(docSnapshot.ref, {
                        acceptOrDecline: true,
                    })

                    console.log('Updated acceptOrDecline field to true')
                })
            }

            // Add a new message indicating the Delivery Order acceptance
            await addDoc(messagesCollectionRef, {
                senderId: currentUser.id,
                DeliveryAcceptOrder: true,
                text: `The Delivery Order ${data.deliveryId} has been accepted!`,
                createdAt: serverTimestamp(), // Use Firestore serverTimestamp
                deliveryDetail: data.deliveryOrderDetails, // Add delivery details
                GenerateGRN: false, // Flag to indicate whether GRN is generated
                purchaseOrderId: data.deliveryId.replace('DO', 'PO'), // Associate with the Purchase Order
            })

            console.log('New message added for Delivery Order acceptance')
        } catch (error) {
            console.error('Error accepting delivery order: ', error)
        }
    }
    //DO Acceptance
    // useEffect(() => {
    //     const updateChat = async () => {
    //         if (decline) {
    //             await updateDoc(doc(db, 'chats', chatId), {
    //                 messages: arrayUnion({
    //                     senderId: currentUser.id,
    //                     text: `The Purchase Order ${purchaseOrderId} has been declined!`,
    //                     createdAt: new Date(),
    //                 }),
    //             })

    //             clearDecline()
    //         }
    //     }

    //     const timer = setTimeout(updateChat, 100)

    //     // Cleanup function to clear the timeout if the component unmounts
    //     return () => clearTimeout(timer)
    // }, [decline])

    const declineDO = async (Id: any) => {
        // Step 1: Reference the messages sub-collection
        const messagesCollectionRef = collection(db, `chats/${chatId}/messages`)

        try {
            // Step 2: Add a new message indicating the Delivery Order has been declined
            await addDoc(messagesCollectionRef, {
                senderId: currentUser.id,
                text: `The Delivery Order ${Id} against Purchase Order ${Id.replace(
                    'DO',
                    'PO'
                )} has been declined!`,
                createdAt: serverTimestamp(), // Use Firestore's serverTimestamp for consistency
            })

            // Step 3: Call the function to cancel the order
            await cancelOrder(Id.replace('DO', 'PO'))

            // Step 4: Query to find the message with the deliveryId
            const messageQuery = query(
                messagesCollectionRef,
                where('deliveryId', '==', Id)
            )
            const messageSnapshot = await getDocs(messageQuery)

            if (!messageSnapshot.empty) {
                // If the message with the deliveryId exists, update its `acceptOrDecline` field
                messageSnapshot.forEach(async (docSnapshot) => {
                    const messageData = docSnapshot.data()

                    // Log the message for debugging purposes
                    console.log('Found message to update: ', messageData)

                    // Update the message with the `acceptOrDecline` field set to true
                    await updateDoc(docSnapshot.ref, {
                        acceptOrDecline: true, // Update the field to indicate that the DO was declined
                    })

                    console.log(
                        'Updated acceptOrDecline field to true for declined Delivery Order'
                    )
                })
            } else {
                console.log(
                    'No matching message found with the given deliveryId'
                )
            }
        } catch (error) {
            console.error('Error declining Delivery Order: ', error)
        }
    }

    useEffect(() => {
        if (chatId !== null) {
            // Create a query to listen to the messages sub-collection, ordered by creation time
            const messagesQuery = query(
                collection(db, `chats/${chatId}/messages`),
                orderBy('createdAt', 'asc') // Ensure the messages are ordered by their creation time
            )

            // Listen to real-time updates in the messages sub-collection
            const unSub = onSnapshot(messagesQuery, (snapshot) => {
                const messagesArray = snapshot.docs.map((doc) => doc.data()) // Extract message data from Firestore docs
                console.log('Chat Array', messagesArray)
                setChat((prevState: any) => ({
                    ...prevState,
                    messages: messagesArray, // Update the chat state with the messages array
                }))
                setDisplayChat(true)
                scrollToBottom() // Scroll to the bottom after fetching messages
            })

            // Cleanup listener on component unmount
            return () => {
                unSub()
            }
        }
    }, [chatId])

    const handleImageClick = (url: string) => {
        setSelectedImageUrl(url)
        setOpenModal(true)
    }

    useEffect(() => {
        // Delay the display of chat by 3 seconds
        const timer = setTimeout(() => {
            setDisplayChat(true)
            scrollToBottom()
        }, 4500)

        return () => clearTimeout(timer)
    }, [])

    const generateDeliveryOrder = (item: any) => {
        setDeliveryOpen(true)
        setPurchaseOrderDetailInfo(item)
        clearAccept()
    }

    const generateGRN = (item: any) => {
        setGRNOpen(true)
        setPurchaseOrderDetailInfo(item)
        clearAccept()
    }

    const handleAcceptGRN = async (GRNId: any) => {
        try {
            // Reference the messages sub-collection
            const messagesCollectionRef = collection(
                db,
                `chats/${chatId}/messages`
            )

            // Query to find the specific message with the matching GRNId
            const messageQuery = query(
                messagesCollectionRef,
                where('GRNId', '==', GRNId)
            )
            const messageSnapshot = await getDocs(messageQuery)

            if (!messageSnapshot.empty) {
                // Step 1: Update the existing message's `acceptOrDeclineGRN` field
                messageSnapshot.forEach(async (docSnapshot) => {
                    const messageData = docSnapshot.data()

                    // Update the GRN status based on the current value
                    await updateDoc(docSnapshot.ref, {
                        acceptOrDeclineGRN:
                            messageData.acceptOrDeclineGRN === 'accept'
                                ? 'invoice'
                                : 'accept',
                    })

                    console.log(
                        'Updated acceptOrDeclineGRN field:',
                        messageData.acceptOrDeclineGRN
                    )
                })
            }

            // Step 2: Add a new message indicating that the GRN has been accepted
            await addDoc(messagesCollectionRef, {
                senderId: currentUser.id,
                acceptanceGRN: true,
                text: `The GRN ${GRNId} has been accepted!`,
                createdAt: serverTimestamp(), // Firestore server timestamp for consistency
            })

            console.log('Added a new message for GRN acceptance')

            // Disable the accept button
            setGRNAccepted(true)
        } catch (error) {
            console.error('Error accepting GRN:', error)
        }
    }

    const handleDeclineGRN = async (message: any) => {
        const { GRNId, purchaseDetail } = message

        try {
            console.log('Purchase Detail in GRN Decline : ', purchaseDetail)
            // Generate the PDF for the invoice
            const pdfBlobInvoice = await pdf(
                <Invoice
                    cartItems={purchaseDetail.cartItems}
                    orderData={purchaseDetail.orderData}
                    restaurant={purchaseDetail.restaurant}
                />
            ).toBlob()

            const pdfUrlInvoice = URL.createObjectURL(pdfBlobInvoice)

            await uploadPDF(
                pdfBlobInvoice,
                purchaseDetail.orderData,
                'addInvoice',
                'invoice',
                purchaseDetail.orderData.OrderID
            )

            await completeOrder(purchaseDetail.orderData.OrderID)

            // Reference the messages sub-collection
            const messagesCollectionRef = collection(
                db,
                `chats/${chatId}/messages`
            )

            // Step 1: Query to find the message with the matching GRNId
            const messageQuery = query(
                messagesCollectionRef,
                where('GRNId', '==', GRNId)
            )
            const messageSnapshot = await getDocs(messageQuery)

            if (!messageSnapshot.empty) {
                // Step 2: Process the messages and update the acceptOrDeclineGRN field
                messageSnapshot.forEach(async (docSnapshot) => {
                    const messageData = docSnapshot.data()

                    if (messageData.acceptOrDeclineGRN === 'accept') {
                        // Generate invoice
                        await updateDoc(docSnapshot.ref, {
                            acceptOrDeclineGRN: 'invoice',
                        })

                        // Add a new message for invoice generation
                        await addDoc(messagesCollectionRef, {
                            senderId: currentUser.id,
                            generateInvoice: true,
                            text: `The ${messageData.GRNId.replace(
                                'GRN',
                                'PO'
                            )} process has been completed. The Sales Invoice ${messageData.GRNId.replace(
                                'GRN',
                                'PO'
                            )} is here.`,
                            createdAt: serverTimestamp(),
                            purchaseOrder: purchaseOrder,
                            InvoicePDF: pdfUrlInvoice,
                            InvoiceId: messageData.GRNId.replace('GRN', 'PO'),
                        })

                        console.log(
                            'Invoice generated and new message added for GRN acceptance'
                        )
                    } else {
                        // Decline the GRN
                        await updateDoc(docSnapshot.ref, {
                            acceptOrDeclineGRN: 'decline',
                        })

                        // Add a new message indicating the GRN has been declined
                        await addDoc(messagesCollectionRef, {
                            senderId: currentUser.id,
                            text: `The GRN ${messageData.GRNId} has been declined.`,
                            createdAt: serverTimestamp(),
                        })

                        // Cancel the order
                        await cancelOrder(
                            messageData.GRNId.replace('GRN', 'PO')
                        )

                        console.log('GRN declined and new message added')
                    }
                })
            } else {
                console.log('No matching message found with the given GRNId')
            }
        } catch (error) {
            console.error('Error declining GRN:', error)
        }
    }

    const [isDeliveryOpen, setDeliveryOpen] = useState(false)
    const [isGRNOpen, setGRNOpen] = useState(false)
    const [purchaseOrderDetailInfo, setPurchaseOrderDetailInfo] = useState()

    return (
        <>
            {isDeliveryOpen && (
                <DeliveryOrderModal
                    isOpen={isDeliveryOpen}
                    onClose={() => setDeliveryOpen(false)}
                    purchaseOrderDetail={purchaseOrderDetailInfo}
                />
            )}

            {isGRNOpen && (
                <GRNModal
                    isOpen={isGRNOpen}
                    onClose={() => setGRNOpen(false)}
                    item={purchaseOrderDetailInfo}
                />
            )}
            {!displayChat && (
                <p
                    style={{
                        textAlign: 'center',
                        marginTop: '10px',
                        color: 'black',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    Loading Messages...
                </p>
            )}
            {displayChat && (
                <div className="chat-messages">
                    {chatId &&
                        chat?.messages?.map((message: any) => (
                            <div
                                key={message?.createdAt}
                                className={
                                    message.purchaseAcceptOrder
                                        ? 'message purchaseAcceptOrder'
                                        : message.DeliveryAcceptOrder
                                        ? 'message DeliveryAcceptOrder'
                                        : message.GRNRequest
                                        ? 'message GRNRequest'
                                        : message.acceptanceGRN
                                        ? 'message acceptanceGRN'
                                        : message.generateInvoice
                                        ? 'message generateInvoice'
                                        : message.purchaseOrder
                                        ? 'message purchaseOrder'
                                        : message.deliveryOrder
                                        ? 'message deliveryOrder'
                                        : message.senderId === currentUser?.id
                                        ? message.productCard
                                            ? 'message productCard'
                                            : 'message own'
                                        : message.productCard
                                        ? 'message productCard'
                                        : 'message'
                                }
                            >
                                <div className="texts">
                                    {message.img && (
                                        <img
                                            src={message.img}
                                            width={70}
                                            height={70}
                                            alt=""
                                            onClick={() =>
                                                handleImageClick(message.img)
                                            }
                                            style={{
                                                cursor: 'pointer',
                                                objectFit: 'cover',
                                            }}
                                        />
                                    )}
                                    <p>
                                        {message.text &&
                                            message.text
                                                .split('/n')
                                                .map(
                                                    (line: any, index: any) => (
                                                        <React.Fragment
                                                            key={index}
                                                        >
                                                            {index === 0 &&
                                                            message.text.split(
                                                                '/n'
                                                            ).length > 1 ? (
                                                                <strong>
                                                                    {line}
                                                                </strong>
                                                            ) : (
                                                                line
                                                            )}
                                                            <br />
                                                        </React.Fragment>
                                                    )
                                                )}
                                    </p>

                                    {/* Starting of invoice */}
                                    {message.purchaseOrder && (
                                        <div className="purchaseOrder">
                                            <Box
                                                display="flex"
                                                gap={2}
                                                className="buttons"
                                            >
                                                <Button
                                                    variant="outlined"
                                                    style={{
                                                        backgroundColor:
                                                            'white',
                                                    }}
                                                    onClick={() =>
                                                        handlePreview(
                                                            message.purchaseId
                                                        )
                                                    }
                                                >
                                                    Preview
                                                </Button>
                                                <Button
                                                    variant="contained"
                                                    color="success"
                                                    style={{
                                                        display:
                                                            message.senderId !==
                                                            currentUser.id
                                                                ? 'block'
                                                                : 'none',
                                                    }}
                                                    disabled={
                                                        message.acceptOrDeclined
                                                    }
                                                    onClick={() =>
                                                        acceptPO(
                                                            message.purchaseId,
                                                            message.purchaseDetail
                                                        )
                                                    }
                                                >
                                                    Accept
                                                </Button>
                                                <Button
                                                    variant="contained"
                                                    color="error"
                                                    style={{
                                                        display:
                                                            message.senderId !==
                                                            currentUser.id
                                                                ? 'block'
                                                                : 'none',
                                                    }}
                                                    onClick={() =>
                                                        declinePO(
                                                            message.purchaseId,
                                                            message.purchaseDetail
                                                        )
                                                    }
                                                    disabled={
                                                        message.acceptOrDeclined
                                                    }
                                                >
                                                    Decline
                                                </Button>
                                            </Box>
                                        </div>
                                    )}

                                    {/* purchase acceptance */}
                                    {message.senderId === currentUser.id &&
                                        message.purchaseAcceptOrder && (
                                            <div className="purchaseOrder">
                                                <Box
                                                    display="flex"
                                                    gap={2}
                                                    className="buttons"
                                                >
                                                    <Button
                                                        variant="contained"
                                                        style={{
                                                            backgroundColor:
                                                                message.generateDO
                                                                    ? '#0000001f'
                                                                    : '#5E239D',
                                                            display:
                                                                message.senderId ===
                                                                currentUser.id
                                                                    ? 'block'
                                                                    : 'none',
                                                            marginTop:
                                                                message.senderId ===
                                                                currentUser.id
                                                                    ? '13px'
                                                                    : '0px',
                                                        }}
                                                        disabled={
                                                            message.generateDO
                                                        }
                                                        onClick={() =>
                                                            generateDeliveryOrder(
                                                                message.purchaseDetail
                                                            )
                                                        }
                                                    >
                                                        Generate DO
                                                    </Button>
                                                </Box>
                                            </div>
                                        )}

                                    {/* delivery order preview */}
                                    {message.deliveryOrder && (
                                        <div className="DeliveryOrder">
                                            <Box
                                                display="flex"
                                                gap={2}
                                                className="buttons"
                                            >
                                                <Button
                                                    variant="outlined"
                                                    style={{
                                                        backgroundColor:
                                                            'white',
                                                    }}
                                                    onClick={() =>
                                                        handleDOPreview(
                                                            message.deliveryOrderId
                                                        )
                                                    }
                                                >
                                                    Preview
                                                </Button>
                                                <Button
                                                    variant="contained"
                                                    color="success"
                                                    style={{
                                                        display:
                                                            message.senderId !==
                                                            currentUser.id
                                                                ? 'block'
                                                                : 'none',
                                                    }}
                                                    onClick={() =>
                                                        acceptDO(message)
                                                    }
                                                    disabled={
                                                        message.acceptOrDecline
                                                    }
                                                >
                                                    Accept
                                                </Button>
                                                <Button
                                                    variant="contained"
                                                    color="error"
                                                    style={{
                                                        display:
                                                            message.senderId !==
                                                            currentUser.id
                                                                ? 'block'
                                                                : 'none',
                                                    }}
                                                    onClick={() =>
                                                        declineDO(
                                                            message.deliveryId
                                                        )
                                                    }
                                                    disabled={
                                                        message.acceptOrDecline
                                                    }
                                                >
                                                    Decline
                                                </Button>
                                            </Box>
                                        </div>
                                    )}

                                    {/* Delivery Accept Order */}
                                    {message.DeliveryAcceptOrder && (
                                        <div className="purchaseOrder">
                                            <Box
                                                display="flex"
                                                gap={2}
                                                className="buttons"
                                            >
                                                <Button
                                                    variant="contained"
                                                    style={{
                                                        backgroundColor:
                                                            message.GenerateGRN
                                                                ? '#0000001f'
                                                                : '#5E239D',
                                                        display:
                                                            message.senderId ===
                                                            currentUser.id
                                                                ? 'block'
                                                                : 'none',
                                                    }}
                                                    onClick={() =>
                                                        generateGRN(message)
                                                    }
                                                    disabled={
                                                        message.GenerateGRN
                                                    }
                                                >
                                                    Generate GRN
                                                </Button>
                                            </Box>
                                        </div>
                                    )}

                                    {/* GRN Request */}
                                    {message.GRNRequest && (
                                        <div className="DeliveryOrder">
                                            <Box
                                                display="flex"
                                                gap={2}
                                                className="buttons"
                                                marginTop={'15px'}
                                            >
                                                <Button
                                                    variant="outlined"
                                                    style={{
                                                        backgroundColor:
                                                            'white',
                                                    }}
                                                    onClick={() =>
                                                        handleGRNPreview(
                                                            message.GRNId
                                                        )
                                                    }
                                                >
                                                    Preview
                                                </Button>
                                                <Button
                                                    variant="contained"
                                                    color="success"
                                                    disabled={
                                                        message.acceptOrDeclineGRN ===
                                                            'accept' ||
                                                        message.acceptOrDeclineGRN ===
                                                            'invoice' ||
                                                        message.acceptOrDeclineGRN ===
                                                            'decline'
                                                            ? true
                                                            : false
                                                    }
                                                    onClick={() =>
                                                        handleAcceptGRN(
                                                            message.GRNId
                                                        )
                                                    }
                                                    style={{
                                                        display:
                                                            message.senderId !==
                                                            currentUser.id
                                                                ? 'block'
                                                                : 'none',
                                                    }}
                                                >
                                                    Accept
                                                </Button>
                                                <Button
                                                    variant="contained"
                                                    color="error"
                                                    onClick={() =>
                                                        handleDeclineGRN(
                                                            message
                                                        )
                                                    }
                                                    style={{
                                                        display:
                                                            message.senderId !==
                                                            currentUser.id
                                                                ? 'block'
                                                                : 'none',
                                                    }}
                                                    disabled={
                                                        message.acceptOrDeclineGRN ===
                                                            'invoice' ||
                                                        message.acceptOrDeclineGRN ===
                                                            'decline'
                                                    }
                                                >
                                                    {message.acceptOrDeclineGRN ===
                                                        'accept' ||
                                                    message.acceptOrDeclineGRN ===
                                                        'invoice'
                                                        ? 'Generate Invoice'
                                                        : 'Decline'}
                                                </Button>
                                            </Box>
                                        </div>
                                    )}

                                    {/* Invoice  */}
                                    {message.generateInvoice && (
                                        <div className="purchaseOrder">
                                            <Box
                                                display="flex"
                                                gap={2}
                                                className="buttons"
                                            >
                                                <Button
                                                    variant="outlined"
                                                    style={{
                                                        backgroundColor:
                                                            'white',
                                                    }}
                                                    onClick={() =>
                                                        handleInvoicePreview(
                                                            message.InvoiceId
                                                        )
                                                    }
                                                >
                                                    Preview
                                                </Button>
                                            </Box>
                                        </div>
                                    )}
                                </div>
                            </div>
                        ))}
                    {img.url && (
                        <div className="message own">
                            <div className="texts">
                                <img
                                    src={img.url}
                                    width={70}
                                    height={70}
                                    alt=""
                                    onClick={() => handleImageClick(img.url)}
                                    style={{
                                        cursor: 'pointer',
                                        objectFit: 'cover',
                                    }}
                                />
                            </div>
                        </div>
                    )}
                </div>
            )}
            <div ref={messagesEndRef}></div>
            <ViewModalPopup
                open={openModal}
                handleClose={() => setOpenModal(false)}
                imageUrl={selectedImageUrl}
            />
        </>
    )
}

export default ChatWindow
